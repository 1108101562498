<template>
  <div v-if="loginView">
    <!--begin::Signin-->
    <div class="login-form login-signin">
      <div class="mb-4 mb-lg-6">
        <h3 class="font-size-h1 font-weight-normal">로그인</h3>
        <p class="font-weight-semi-bold font-size-lg mt-5">
          첫 방문이신가요?
          <router-link
              class="ml-2"
              :to="{ name: 'register' }"
            >
            <span class="font-weight-bold text-dark"><u>회원 가입</u></span>
          </router-link>
          /
          <router-link
              :to="{ name: 'pwdReset' }"
            >
            <span class="font-weight-bold text-dark"><u>비밀번호 재설정</u></span>
          </router-link>
        </p>
      </div>
      <!--begin::Form-->
      <div class="form" v-on:keyup.enter="onSubmit">
<!--        <div-->
<!--          role="alert"-->
<!--          v-bind:class="{ show: errors && errors.length }"-->
<!--          class="alert fade alert-danger"-->
<!--        >-->
<!--          <div class="alert-text" v-for="(error, i) in errors" :key="i">-->
<!--            {{ error }}-->
<!--          </div>-->
<!--        </div>-->

        <b-form-group
          id="example-input-group-1"
          label=""
          label-for="example-input-1"
        >
          <div class="d-flex justify-content-between">
            <label>아이디</label>
<!--            <a href="javascript:;" class="text-hover-primary">-->
<!--              <span class="font-weight-bold">아이디 찾기</span>-->
<!--            </a>-->
          </div>
          <b-form-input
            class="form-control form-control-solid h-auto py-5 px-6"
            v-bind:class="{'is-invalid': idValidate}"
            id="example-input-1"
            size="lg"
            v-model="$v.form.id.$model"
            :state="validateState('id')"
            aria-describedby="input-1-live-feedback"
            placeholder="아이디를 입력해주세요."
            maxlength='30'
          ></b-form-input>

          <b-form-invalid-feedback id="input-1-live-feedback">
            아이디를 {{ idValidate ? '확인' : '입력' }}해주세요.
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          id="example-input-group-2"
          label=""
          label-for="example-input-2"
        >
          <div class="d-flex justify-content-between">
            <label>비밀번호</label>
<!--            <a href="javascript:;" class="text-hover-primary">-->
<!--              <span class="font-weight-bold">비밀번호 찾기</span>-->
<!--            </a>-->
          </div>
          <b-form-input
            class="form-control form-control-solid h-auto py-5 px-6"
            v-bind:class="{'is-invalid': pwdValidate}"
            type="password"
            id="example-input-2"
            size="lg"
            v-model="$v.form.password.$model"
            :state="validateState('password')"
            aria-describedby="input-2-live-feedback"
            placeholder="비밀번호를 입력해주세요."
            maxlength='30'
          ></b-form-input>

          <b-form-invalid-feedback id="input-2-live-feedback">
            비밀번호를 {{ pwdValidate ? '확인' : '입력' }}해주세요.
          </b-form-invalid-feedback>
        </b-form-group>

        <div class="form-group fv-plugins-icon-container">
          <label class="checkbox mb-0">
            <input v-model="stay" type="checkbox" name="agree">
            <span class="mr-2"></span> 자동 로그인
          </label>
        </div>

        <!--begin::Action-->
        <div
          class="form-group d-flex flex-wrap justify-content-between align-items-center"
        >
          <b-button
            block
            variant="primary"
            ref="signinBtn"
            class="px-9 py-4 my-3"
            @click="onSubmit"
          >
            <span class="font-weight-bold font-size-h5">로그인</span>
          </b-button>
        </div>
        <!--end::Action-->
      </div>
      <!--end::Form-->
    </div>
    <!--end::Signin-->
  </div>
</template>

<style lang="scss" scoped>
.spinner.spinner-right {
  padding-right: 3.5rem !important;
}
</style>

<script>
import {validationMixin} from 'vuelidate';
import {minLength, required} from 'vuelidate/lib/validators';
import {getResult, isSuccess} from '@/core/services/funcs';
import {mapGetters} from 'vuex';
import {ACT_ADD_ALERT} from '@/core/services/store/alert.module';
import {LOGIN, SET_AUTH,REGISTER_DEVICE_TOKEN, SET_APIKEY, SET_DEVICE, SET_PUSH_ITEM} from '@/core/services/store/auth.module';
import {ANDROID_API_KEY, IOS_API_KEY} from '@/core/services/variable';

export default {
  mixins: [validationMixin],
  name: "login",
  data() {
    return {
      loginView: false,
      // Remove this dummy login info
      form: {
        id: '',
        password: ''
      },
      idValidate: false,
      pwdValidate: false,
      loaded: false,
      stay: true
    };
  },
  beforeMount() {
    if(this.isAuthenticated) {
      this.$router.push({ name: "portal" });
    }else{
      this.loginView = true
    }
  },
  watch: {
    'form.id'(){
      if(this.idValidate) this.idValidate = false
    },
    'form.password'(){
      if(this.pwdValidate) this.pwdValidate = false
    },
  },
  validations: {
    form: {
      id: {
        required,
        minLength: minLength(1)
      },
      password: {
        required,
        minLength: minLength(3)
      }
    }
  },
  computed: {
    ...mapGetters([
      "isAuthenticated",
      "loginStayYn"
    ]),
  },
  methods: {
    initPushNotification() {
      document.addEventListener(
      typeof cordova !== 'undefined' ? 'deviceready' : 'DOMContentLoaded',
      () => {
        if (window.device) {
          const device = window.device;
          this.$store.commit(SET_DEVICE, device);
          const platform = device.platform.toLowerCase();

          if(platform === 'ios') this.initIOS();
          else if(platform === 'android') this.initAndroid();

          if(window.PushNotification) this.initNotification()
        }
      });
    },
    initNotification () {
      const options = {
        android: {
          // icon:'icon',
          // icon:'notification_icon',
          // iconColor:'#2a2c36'
        },
        ios: {
          alert: "true",
          badge: "true",
          sound: "true"
        },
        windows: {}
      }
      options.android.topics = ["T-FarmON-Notification"];
      options.ios.topics = ["T-FarmON-Notification"];

      const push = window.PushNotification.init(options)
      this.$store.commit(SET_PUSH_ITEM, push);

      push.on('registration', data => {
        this.$store.dispatch(REGISTER_DEVICE_TOKEN, {
          pushId: data.registrationId,
          pushType: data.registrationType
        })
        .then(() => {})
        .catch(e => {console.error(e)});
      })

      push.on('error', function(e) {
        console.error('push error = ' + e.message);
      })

      push.on('notification', data => {
        console.log(data);
      })
    },
    initIOS () {
      this.$store.commit(SET_APIKEY, IOS_API_KEY);
    },
    initAndroid () {
      this.$store.commit(SET_APIKEY, ANDROID_API_KEY);
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    onSubmit() {
      if(this.loaded) return;

      this.$v.form.$touch();
      if (this.$v.form.$anyError) return;

      this.loaded = true

      const id = this.$v.form.id.$model;
      const password = this.$v.form.password.$model;
      const stayYn = this.stay ? 'Y' : 'N'

      // set spinner to submit button
      this.$refs.signinBtn.classList.add("spinner", "spinner-light", "spinner-right");

      this.$store.dispatch(LOGIN, {id: id, password: password, stayYn: stayYn})
      .then(resp => {
        if(isSuccess(resp)){
          this.$store.commit(SET_AUTH, {uuid: resp.uuid, mno: resp.mno, authToken: resp.authToken, refreshToken: resp.refreshToken, loginStayYn: stayYn});
          // this.$store.dispatch(ACT_ADD_ALERT, {message: '로그인에 성공하셨습니다.', color: 'success'});
          if (this.$route.query.returnUrl){
            // this.$router.push({ path: "this.$route.query.returnUrl" })  https:/www.tfarmon.com/
            window.location.href = this.$route.query.returnUrl;
          } else {
            this.$router.push({ name: "portal" });
          }
          this.initPushNotification()
        }else{
          const result = getResult(resp)
          this.$store.dispatch(ACT_ADD_ALERT, {message: result.message});
          if(result.number == 301){
            this.idValidate = true;
            this.pwdValidate = false;
          }else if(result.number == 302){
            this.idValidate = false;
            this.pwdValidate = true;
          }
          this.clearLoaded();
        }
      }).catch(e => {
        console.error(e);
        this.$store.dispatch(ACT_ADD_ALERT, {message: '오류가 발생했습니다. 잠시 후 다시 시도해주세요.'});
        this.clearLoaded();
      })
    },
    clearLoaded(){
      this.$refs.signinBtn.classList.remove("spinner", "spinner-light", "spinner-right");
      this.loaded = false
    },
  },
};
</script>
